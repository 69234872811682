
import popupRecharge from '@/components/popup-recharge.vue'
import { defineComponent } from 'vue'
import insureTeamCompany from './insure-team-company.vue'
import {
    insureDeductionExport,
    insureDeductionDetailExport,
    insuranceDetailedExport
} from '@/api/export'
import store from '@/store/index'
import {
    insureDeduction,
    insureDeductionDetailListDto,
    insureChargeAccount,
    insureBalance
} from '@/api/insure-deduction'
import batchInsureVue from '@/components/batch-insure.vue'
import user from '@/utils/user'
import axios from 'axios'
import { downLoadFile } from '../utils/util'
export default defineComponent({
    name: 'TeamInsure',
    components: {
        batchInsureVue,
        insureTeamCompany,
        popupRecharge
    },
    data () {
        return {
            isRecharge: false,
            openBank: '',
            bankName: '',
            bankNo: '',
            projectTime: '',
            pageSize: 10,
            pageNum: 1,
            total: 0 as number,
            tableData: [] as insureDeductionDetailListDto[],
            searchText: '',
            selects: [] as string[],
            insureCount: {} as {
                amount: number
                insureProjectCount: number
                policyPeopleCount: number
            },
            isGsc: false,
            isInsure: false,
            balanceObj: {} as any
        }
    },
    async created () {
        user.waitLogin().then(() => {
            this.isGsc = user.isGsc
            this.openBank = '中国工商银行深圳市东门支行'
            this.bankName = '微民保险代理有限公司'
        })
        this.insureDeductionList()
        this.getInsureBalance()
        const result = await insureChargeAccount()
        this.bankNo = result
        console.log(result, '充值信息')
    },
    methods: {
        async getInsureBalance () {
            const result = await insureBalance({
                insureCompanyType: 'wesure004_rb'
            })
            this.balanceObj = result
        },
        onAddCompany () {
            let dialogVisible = '' as any
            dialogVisible = this.$refs.teamCompany
            dialogVisible.onAddCompany()
        },
        async insureDeductionList () {
            const result = await insureDeduction({
                beginCreateTime: this.projectTime ? this.projectTime[0] : '',
                endCreateTime: this.projectTime ? this.projectTime[1] : '',
                searchText: this.searchText,
                pageSize: this.pageSize,
                pageNum: this.pageNum,
                insureCompanyType: 'wesure004_rb'
            })
            this.total = result.totalCount
            this.tableData = result.list
            this.insureCount = result.insureCount
        },
        handleSizeChange () {
            this.pageNum = 1
            this.insureDeductionList()
        },
        search () {
            this.pageNum = 1
            this.insureDeductionList()
        },
        onReset () {
            this.searchText = ''
            this.search()
        },
        handleSelectionChange (selects: { id: string }[]) {
            this.selects = selects.map(item => item.id)
            console.log(this.selects, 'selects')
        },
        /**
         * 导出勾选
         */
        async onExport () {
            console.log('导出了')
            const result = await insureDeductionExport({
                beginCreateTime: this.projectTime ? this.projectTime[0] : '',
                endCreateTime: this.projectTime ? this.projectTime[1] : '',
                searchText: this.searchText,
                insureCompanyType: 'wesure004_rb'
            }, {
                loading: true
            })
            if (!result.fileUrl && result.exportStatus === 0) {
                store.commit('getLongPollingFile', result)
            } else {
                axios({
                    url: result.fileUrl,
                    method: 'GET',
                    responseType: 'blob'
                }).then(async (response) => {
                    await downLoadFile(response, result.exportType, result.updateTime, result.remark)
                    await this.$message.success('导出成功！')
                })
            }
            // window.location.href =
            //     process.env.VUE_APP_BASE_URL +
            //     '/common/download?delete=true&fileName=' +
            //     result

            // this.$message.success('导出成功！')
        },
        async onSingleExport (id: string) {
            console.log(id, 'id', this.selects, 'selects')
            const result = await insureDeductionDetailExport({
                ids: this.selects.toString() || id
            })

            window.location.href =
                process.env.VUE_APP_BASE_URL +
                '/common/download?delete=true&fileName=' +
                result

            this.$message.success('导出成功！')
        },
        async onExportDetail () {
            const result = await insuranceDetailedExport({
                beginCreateTime: this.projectTime ? this.projectTime[0] : '',
                endCreateTime: this.projectTime ? this.projectTime[1] : '',
                searchText: this.searchText,
                insureCompanyType: 'wesure004_rb'
            }, {
                loading: true
            })
            if (!result.fileUrl && result.exportStatus === 0) {
                store.commit('getLongPollingFile', result)
            } else {
                axios({
                    url: result.fileUrl,
                    method: 'GET',
                    responseType: 'blob'
                }).then(async (response) => {
                    await downLoadFile(response, result.exportType, result.updateTime, result.remark)
                    await this.$message.success('导出成功！')
                })
            }
            // window.location.href =
            //     process.env.VUE_APP_BASE_URL +
            //     '/common/download?delete=true&fileName=' +
            //     result

            // this.$message.success('导出成功！')
        },
        insureComplete () {
            this.isInsure = false
            this.handleSizeChange()
        },
        onCloseCallback () {
            this.isInsure = false
        }
    }
})
